import React from "react";
import { graphql, PageProps } from "gatsby";

import NavigationScrollbar from "../components/NavigationScrollbar";
import { ProductCategoryHero } from "../components/ProductCategory";
import { PaddingContainer } from "../components/Containers";
import ProductOverview from "../components/ProductOverview";
import { Product } from "../models";
import SEO from "../components/SEO";

const DutchProductCategoryPage: React.FC<PageProps> = ({ location, data }) => {
  const { productCategory } = data as any;
  const { slug, name, description } = productCategory;
  const products: Product[] = productCategory.products;

  return (
    <>
      <SEO title={name} lang="nl" pathName={location.pathname} />
      <NavigationScrollbar />
      <PaddingContainer>
        <ProductCategoryHero
          title={name}
          description={description}
        ></ProductCategoryHero>
        <ProductOverview
          title="Producten"
          productCategorySlug={slug}
          products={products}
        />
      </PaddingContainer>
    </>
  );
};

export const productCategoryQuery = graphql`
  query DutchProductCategoryQuery($productCategorySlug: String!) {
    productCategory: strapiProductCategory(slug: { eq: $productCategorySlug }) {
      slug
      name: dutchName
      description: dutchDescription
      products {
        id: productId
        slug
        name: dutchName
        price
        shippingCost
        coverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`;

export default DutchProductCategoryPage;
